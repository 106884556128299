<template>
  <div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показать</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>элементов</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model.trim="searchQuery"
                  class="d-inline-block"
                  placeholder="Поиск..."
                  :class="{
                    'mr-1': $store.getters['workingMode/checkPermission']('manage_roles')
                  }"
                />
                <router-link
                  v-if="$store.getters['workingMode/checkPermission']('manage_roles')"
                  v-slot="{navigate}"
                  :to="{ name: 'roles.create' }"
                  custom
                >
                  <b-button
                    variant="primary"
                    @click="navigate"
                  >
                    <span class="text-nowrap">Создать</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refCompanyListTable"
          class="position-relative list-view-table"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Ничего не найдено!"
          :sort-desc.sync="isSortDirDesc"
          no-local-sorting
        >
          <template #cell(name)="data">
            <b-link :to="{ name: 'roles.show', params: { id: data.item.id } }">
              {{ data.item.name }}
            </b-link>
          </template>

          <template #cell(desc)="data">
            <small
              v-if="data.item.company_id === null"
              class="text-info d-block"
            >
              Предзаданная роль (Невозможно отредактировать)
            </small>
            {{ data.item.desc }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'roles.show', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Посмотреть</span>
              </b-dropdown-item>

              <template
                v-if="data.item.company_id !== null && $store.getters['workingMode/checkPermission']('manage_roles')"
              >
                <b-dropdown-item :to="{ name: 'roles.edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Изменить</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteItem(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Удалить</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ from }} по {{ to }} из {{ totalItems }} елементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BOverlay, BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { getCurrentInstance } from 'vue'
import useCrudList from '@/composables/useCrudList'
import { getRoles, deleteRole } from '@/services/main-api/business/roles'

export default {
  name: 'RolesListView',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BOverlay,
    BLink,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const getItems = async () => {
      let search = null
      if (instance.searchQuery) {
        search = `${instance.searchQuery}`
      }

      try {
        const res = await getRoles({
          company: instance.$store.getters['workingMode/company_id'],
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'name',
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'desc', label: 'Описание', sortable: false },
        { key: 'actions', label: 'Действия' },
      ],
      getItems,
      deleteEntity: item => deleteRole(item.id),
      getQuestionTextBeforeDeletion: item => `Роль ${item.name} будет удалена`,
      getTextAfterDeletion: () => 'Роль была удалена.',
    })

    return {
      loading,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      perPage,
      perPageOptions,
      from,
      to,
      currentPage,
      totalItems,

      items,

      deleteItem,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
